import React, { useState, useEffect } from 'react'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { initializeApp } from 'firebase/app'
import { getAuth, onAuthStateChanged, User as FirebaseUser } from 'firebase/auth'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import BundlePage from './BundlePage'
import Success from './Success'


const GOOGLE_CLIENT_ID = '531953251910-o7elr4bpb1uk4fk386p0nkjff31ri4vh.apps.googleusercontent.com'

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA6LsmzgnjF6GI51Eo7PKoyNBQRLhGUfF8",
  authDomain: "codemam-backend.firebaseapp.com",
  projectId: "codemam-backend",
  storageBucket: "codemam-backend.firebasestorage.app",
  messagingSenderId: "531953251910",
  appId: "1:531953251910:web:4506d3d44b11d82c557c47",
  measurementId: "G-WLK6KY4HM9"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig)
const auth = getAuth(firebaseApp)

export interface User {
  displayName: string
  email: string
  photoURL?: string
}

function App() {
  const [user, setUser] = useState<User | null>(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (firebaseUser: FirebaseUser | null) => {
      if (firebaseUser && firebaseUser.email) {
        setUser({
          displayName: firebaseUser.displayName || '',
          email: firebaseUser.email,
          photoURL: firebaseUser.photoURL || undefined
        })
      } else {
        setUser(null)
      }
      setLoading(false)
    })

    return () => unsubscribe()
  }, [])

  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/ProBundle" replace />} />
          <Route path="/:productName" element={<BundlePage user={user} />} />
          <Route path="/:productName/:plan" element={<BundlePage user={user} />} />
          <Route path="/success" element={<Success />} />
        </Routes>
      </Router>
    </GoogleOAuthProvider>
  )
}

export default App
